.sr-bb {
    font-family: "Roboto", "Noto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

.sr-bb .sr-bb {
    background: none;
}

.sr-bb .srt-base-1 {
    background-color: #fff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-1-draw {
    background-color: transparent;
    color: #aaa;
    border-color: #aaa;
}

.sr-bb .srt-base-1-is-active {
    background-color: #f2f2f2;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
    background-color: #ebebeb;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
    background-color: #f2f2f2;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-1-background {
    background-color: white;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-2 {
    background-color: white;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-2-is-active {
    background-color: #e0ecff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
    background-color: #e0ecff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-3 {
    background-color: #e0ecff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-3-is-active {
    background-color: #d4e4ff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
    background-color: #d4e4ff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-3-background {
    background-color: white;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-4 {
    background-color: #f2f7ff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-5-is-active {
    background-color: #bdd6ff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
    background-color: #bdd6ff;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-6 {
    background-color: transparent;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
    background-color: transparent;
    color: #e4e4e4;
    border-color: #e4e4e4;
}

.sr-bb .srt-base-1-neutral-2 {
    background-color: transparent;
    color: #474747;
    border-color: #474747;
}

.sr-bb .srt-base-1-neutral-3 {
    background-color: transparent;
    color: rgba(51, 51, 51, 0.12);
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
    background-color: transparent;
    color: #7a7a7a;
    border-color: #7a7a7a;
}

.sr-bb .srt-base-1-neutral-5 {
    background-color: transparent;
    color: #666666;
    border-color: #666666;
}

.sr-bb .srt-base-1-neutral-6 {
    background-color: transparent;
    color: #cecece;
    border-color: #cecece;
}

.sr-bb .srt-base-1-neutral-7 {
    background-color: transparent;
    color: #7a7a7a;
    border-color: #7a7a7a;
}

.sr-bb .srt-base-1-neutral-8 {
    background-color: transparent;
    color: #e4e4e4;
    border-color: #e4e4e4;
}

.sr-bb .srt-base-1-neutral-9 {
    background-color: transparent;
    color: #f3f3f3;
    border-color: #f3f3f3;
}

.sr-bb .srt-base-1-neutral-10 {
    background-color: transparent;
    color: #e7e7e7;
    border-color: #e7e7e7;
}

.sr-bb .srt-base-1-neutral-11 {
    background-color: transparent;
    color: #adadad;
    border-color: #adadad;
}

.sr-bb .srt-base-1-neutral-12 {
    background-color: transparent;
    color: #707070;
    border-color: #707070;
}

.sr-bb .srt-base-1-neutral-13 {
    background-color: transparent;
    color: #ebebeb;
    border-color: #ebebeb;
}

.sr-bb .srt-neutral-1 {
    background-color: #e4e4e4;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-neutral-2 {
    background-color: #474747;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-3 {
    background-color: rgba(51, 51, 51, 0.12);
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-neutral-4 {
    background-color: #7a7a7a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-5 {
    background-color: #666666;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-6 {
    background-color: #cecece;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-neutral-7 {
    background-color: #7a7a7a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-8 {
    background-color: #e4e4e4;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-neutral-9 {
    background-color: #f3f3f3;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-neutral-10 {
    background-color: #e7e7e7;
    color: rgba(51, 51, 51, 0.82);
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-neutral-11 {
    background-color: #adadad;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-neutral-12 {
    background-color: #707070;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-13 {
    background-color: #ebebeb;
    color: #333333;
    border-color: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-text-secondary {
    opacity: 0.7;
}

.sr-bb .srt-text-disabled {
    opacity: 0.35;
}

.sr-bb .srt-text-tertiary {
    opacity: 0.54;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
    opacity: 0.8;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
    opacity: 0.6;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
    opacity: 0.7;
}

.sr-bb .srt-icon {
    opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
    opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
    box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
    box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
    box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
    box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
    box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
    fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
    stroke: #2579ad;
}

.sr-bb .srt-fill-soccer-yellow-card {
    fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
    stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
    stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
    stroke: #4fbe30;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
    fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
    fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
    fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
    stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
    fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
    stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
    fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
    stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
    fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
    stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
    fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
    stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
    fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
    stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
    fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
    stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
    fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
    stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
    fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
    stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
    fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
    stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
    background-color: #f5a623;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
    background-color: rgba(245, 166, 35, 0.3);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-penalty {
    background-color: #f4a621;
    color: #ffffff;
    border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
    background-color: #ac182e;
    color: #ffffff;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
    background-color: #ac182e;
    color: #ffffff;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
    background-color: #4b90de;
    color: #ffffff;
    border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
    background-color: #f5a623;
    color: #ffffff;
    border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
    fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
    stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
    fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
    stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
    fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
    stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
    background-color: #009900;
    color: #ffffff;
    border-color: #009900;
}

.sr-bb .srt-mlb-run-2 {
    background-color: #ffffff;
    color: #009900;
    border-color: #009900;
}

.sr-bb .srt-mlb-hit-1 {
    background-color: #f7ab11;
    color: #333333;
    border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
    background-color: #ffffff;
    color: #f7ab11;
    border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
    background-color: #00a4ff;
    color: #ffffff;
    border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
    background-color: #d0021b;
    color: #ffffff;
    border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
    background-color: #ffffff;
    color: #d0021b;
    border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
    background-color: #212121;
    color: #ffffff;
    border-color: #ffffff;
}

.sr-bb .srt-fill-mlb-base {
    fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
    fill: #00a4ff;
}

.sr-bb .srt-fill-text {
    fill: #333333;
}

.sr-bb .srt-fill-text-invert {
    fill: #ffffff;
}

.sr-bb .srt-fill-text-secondary {
    fill-opacity: 0.7;
}

.sr-bb .srt-fill-text-disabled {
    fill-opacity: 0.35;
}

.sr-bb .srt-fill-base-1 {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-active-2 {
    fill: #ebebeb;
}

.sr-bb .srt-stroke-base-1-active-2 {
    stroke: #ebebeb;
}

.sr-bb .srt-fill-base-1-primary {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
    stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
    fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
    stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
    fill: #e0ecff;
}

.sr-bb .srt-stroke-base-2-active {
    stroke: #e0ecff;
}

.sr-bb .srt-fill-base-2-hover {
    fill: #e0ecff;
}

.sr-bb .srt-stroke-base-2-hover {
    stroke: #e0ecff;
}

.sr-bb .srt-fill-base-3 {
    fill: #e0ecff;
}

.sr-bb .srt-stroke-base-3 {
    stroke: #e0ecff;
}

.sr-bb .srt-fill-base-3-active {
    fill: #d4e4ff;
}

.sr-bb .srt-stroke-base-3-active {
    stroke: #d4e4ff;
}

.sr-bb .srt-fill-base-3-hover {
    fill: #d4e4ff;
}

.sr-bb .srt-stroke-base-3-hover {
    stroke: #d4e4ff;
}

.sr-bb .srt-fill-neutral-1 {
    fill: #e4e4e4;
}

.sr-bb .srt-stroke-neutral-1 {
    stroke: #e4e4e4;
}

.sr-bb .srt-fill-neutral-2 {
    fill: #474747;
}

.sr-bb .srt-stroke-neutral-2 {
    stroke: #474747;
}

.sr-bb .srt-fill-neutral-3 {
    fill: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
    stroke: rgba(51, 51, 51, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
    fill: #7a7a7a;
}

.sr-bb .srt-stroke-neutral-4 {
    stroke: #7a7a7a;
}

.sr-bb .srt-fill-neutral-5 {
    fill: #666666;
}

.sr-bb .srt-stroke-neutral-5 {
    stroke: #666666;
}

.sr-bb .srt-fill-neutral-6 {
    fill: #cecece;
}

.sr-bb .srt-stroke-neutral-6 {
    stroke: #cecece;
}

.sr-bb .srt-fill-neutral-7 {
    fill: #7a7a7a;
}

.sr-bb .srt-stroke-neutral-7 {
    stroke: #7a7a7a;
}

.sr-bb .srt-fill-neutral-8 {
    fill: #e4e4e4;
}

.sr-bb .srt-stroke-neutral-8 {
    stroke: #e4e4e4;
}

.sr-bb .srt-fill-neutral-9 {
    fill: #f3f3f3;
}

.sr-bb .srt-stroke-neutral-9 {
    stroke: #f3f3f3;
}

.sr-bb .srt-fill-neutral-10 {
    fill: #e7e7e7;
}

.sr-bb .srt-stroke-neutral-10 {
    stroke: #e7e7e7;
}

.sr-bb .srt-fill-neutral-11 {
    fill: #adadad;
}

.sr-bb .srt-stroke-neutral-11 {
    stroke: #adadad;
}

.sr-bb .srt-fill-neutral-12 {
    fill: #707070;
}

.sr-bb .srt-stroke-neutral-12 {
    stroke: #707070;
}

.sr-bb .srt-fill-neutral-13 {
    fill: #ebebeb;
}

.sr-bb .srt-stroke-neutral-13 {
    stroke: #ebebeb;
}

.sr-bb .srt-stop-base-1 {
    stop-color: transparent;
}

.sr-bb .srt-fill-neutral-14 {
    fill: #FFFFFF;
}

.sr-bb .srt-stroke-neutral-14 {
    stroke: #FFFFFF;
}

.sr-bb .srt-logo-powered-by-light, .sr-bb .srt-logo-powered-by-dark,
.sr-bb .sr-lmt-0-ms-logo__wrapper,
.sr-bb .sr-lmt-1-ms-matchinfovenue__logo,
.sr-bb .sr-lmt-clock {
    display: none !important;
}

.sr-bb .sr-lmt-1-ms-matchinfocountdown__row-main {
    border-bottom: 0 none !important;
}
.sr-bb .sr-lmt-1-ms-matchinfocountdown__row-main:not(:first-child) {
    border-top-width: 1px !important;
    border-top-style: solid !important;
}

